/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 90.10416667%;
}
#home {
  margin-top: 30px;
}
#wrapper {
  display: flex;
  flex-direction: column;
}
.ie11 #wrapper {
  display: block;
}
.footeratbottomthankstoflexbox {
  flex: 1;
}
.section--header {
  position: fixed;
  transition: all 0.3s;
  top: 0;
}
#edit .section--header {
  position: absolute;
}
#view .section--hide.navi--hidden .section--header {
  top: -146px;
}
.section--navigation {
  transition: all 0.4s;
  top: 0;
}
#view .section--hide.navi--hidden .section--navigation {
  top: -80px;
}
.maincontent {
  margin-top: 234px;
}
.footarea {
  width: 102.89017341%;
  margin-left: -1.44508671%;
  margin-top: 30px;
}
.footpart {
  width: 47.19101124%;
  margin: 30px 1.40449438%;
}
@media (max-width: 1024px) {
  .footpart--3 {
    width: 97.19101124%;
  }
}
@media only screen and (max-width: 1023px) {
  .cb-layout4 .unit h2 {
    font-size: 24px;
    line-height: 1.17;
  }
  h3 {
    font-size: 24px;
    line-height: 1.17;
  }
}
.area {
  width: 594px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 594px;
}
.area h2,
.area .foot {
  margin-right: 10px;
  margin-left: 10px;
}
.area .part,
.area > .grid table {
  margin-right: 10px;
  margin-left: 10px;
  width: 574px;
}
.area .tiny {
  width: 277px;
}
.area > .slim {
  width: 297px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 277px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 10px;
  margin-left: 10px;
}
.cb-layout4 .main {
  width: 100%;
}
.cb-layout4 .main > .unit {
  margin-right: 1.40449438%;
  margin-left: 1.40449438%;
  width: 97.19101124%;
}
.cb-layout4 .main h2,
.cb-layout4 .main .foot {
  margin-right: 2.89017341%;
  margin-left: 2.89017341%;
}
.cb-layout4 .main .part,
.cb-layout4 .main > .grid table {
  margin-right: 2.89017341%;
  margin-left: 2.89017341%;
  width: 94.21965318%;
}
.cb-layout4 .main .tiny {
  width: 44.21965318%;
}
.cb-layout4 .main > .slim {
  width: 47.19101124%;
}
.cb-layout4 .main > .slim h2,
.cb-layout4 .main > .slim .foot,
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  margin-right: 5.95238095%;
  margin-left: 5.95238095%;
}
.cb-layout4 .main > .slim .part,
.cb-layout4 .main > .slim.grid table {
  width: 88.0952381%;
}
.cb-layout4 .main > .slim .tiny {
  width: 88.0952381%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  margin-left: -10px;
}
.cb-layout4 .main {
  width: 102.89017341%;
  margin-left: -1.44508671%;
}
.cb-layout3 div.main div.unit div.tiny {
  width: 277px;
}
.cb-layout4 div.main div.unit.wide {
  width: 47.19101124%;
}
.cb-layout4 div.main div.unit.wide div.head h2,
.cb-layout4 div.main div.unit.wide div.part {
  margin-left: 5.95238095%;
  margin-right: 5.95238095%;
}
.cb-layout4 div.main div.unit.wide div.part {
  width: 88.0952381%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/* scan-large.less 2013-1-16 */
.scan li,
.scan li > .cb-hybrid,
.scan span,
.same > .cb-hybrid {
  padding: 0 0.3em;
}
th.prev,
th.next {
  font-weight: normal;
}
.scan th.prev,
.scan td.prev {
  text-align: left;
}
.scan th.next,
.scan td.next {
  text-align: right;
}
.same,
.this .same,
.same > .cb-hybrid {
  background: #004896;
  color: #fff;
}
.same,
.mese .same > span {
  background-color: #004896;
}
.this .same,
.this .same > .cb-hybrid,
.mese.this .same > span {
  background-color: #003063;
}
.mese .same {
  background: none;
}
/******* module-album-large.less 2013-1-16 *******/
.cb-manual > .head > h2 {
  padding-right: 40px;
  min-height: 24px;
}
.aspt,
.cb-sort-mode {
  display: inline-block;
  background-color: #004896;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  float: left;
  text-shadow: none;
}
.aspt:hover,
.cb-sort-mode:hover,
.aspt:focus,
.cb-sort-mode:focus {
  color: #fff;
  background-color: #004fa5;
  text-decoration: none;
  cursor: pointer;
}
.aspt:active,
.cb-sort-mode:active {
  outline: none;
  background-color: #003063;
}
.aspt {
  margin-right: 0.5em;
}
/******* module-filter-medium.less 2013-1-16 *******/
.cb-mobile-filter-items > .beta > span {
  width: 14.28571429%;
}
/*# sourceMappingURL=./screen-medium.css.map */